.tag-input-error {
    border: 1px #D14343 solid;
    &:focus {
        border: 1px solid #ADC2FF !important;
        transition: box-shadow 80ms ease-in-out;
        box-shadow: 0 0 0 2px #d6e0ff;
    }
}

.tag-input-default {
    border: 1px solid #d8dae5 !important;
    &:focus {
        border: 1px solid #ADC2FF !important;
        transition: box-shadow 80ms ease-in-out;
        box-shadow: 0 0 0 2px #d6e0ff;
    }
}